.documents_pdf {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.document-bg {
  position: absolute;
  width: 100%;
  height: 1240px;
  background-image: url(./img/bg.png);
  background-size: cover;
  z-index: 0;
}

.documents-title {
  margin: 0 auto !important;
  text-align: center;
  font-family: Montserrat700;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-transform: uppercase;
  margin: 0 0 16px;
  color: #000000;
  text-align: center;
  z-index: 1;
}

.documents_wrapper {
  & > canvas {
    width: 100px !important;
  }
}

.react-pdf__Page {
  margin: 0 auto;
}

.react-pdf__Page__canvas {
  position: relative;
  width: 730px !important;
  height: auto !important;
  border: 2px #ffffff;
  filter: drop-shadow(0px 3.99638px 3.99638px rgba(0, 0, 0, 0.25));
  margin: 20px auto;
}

.documents_box-button {
  display: flex;
  justify-content: space-between;
  width: 80%;
  z-index: 1;
}

.react-pdf__Page__textContent {
  display: none;
}

@media (max-width: 750px) {
  .document-bg {
    height: 730px;
  }

  .react-pdf__Page__canvas {
    width: 350px !important;
  }

  .documents_box-button {
    font-size: 15px;

    & > button {
      width: 140px;
      height: 45px;
      font-size: 12px;
    }
  }
}
