.about-as {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 1180px;
  margin: 160px auto 0;

  &_title {
  }

  &_sub-title {
    font-family: Montserrat700;
    font-size: 32px;
    line-height: 39px;
    margin-top: 48px;
    margin-bottom: 48px;
    text-transform: uppercase;
  }

  &_list > .list > ul {
    width: 785px;
    padding-left: 24px;

    & > li {
      padding-left: 50px;
    }
  }

  &_modail-info {
    position: relative;
    display: flex;
    margin: 76px 0 81px;
    padding: 27px 105px 53px;
    background: linear-gradient(90.3deg, #fffcfc51 6.36%, #ffffff84 101.69%);
    box-shadow: 0px 0px 25px #00000012;
    border-radius: 58.5px;

    & > span {
      max-width: 970px;
      font-family: Montserrat400;
      font-size: 23px;
      line-height: 28px;
    }
  }

  &_creater {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 25px;
  }
}

.our-volunteers {
  font-style: normal;
  font-weight: 800;
  font-size: 46px;
  line-height: 50px;
  text-transform: uppercase;

  margin: 0 0 84px;
  color: #000000;
}

.create {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;

  &_photo {
    width: 233px;
    height: 233px;
    margin-bottom: 32px;
    border-radius: 50%;
  }

  &_name {
    font-family: Montserrat700;
    font-size: 23px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 16px;
  }

  &_faq {
    font-family: Montserrat400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
}

.our-documents {
  margin: 160px 0;

  &_title {
    font-family: Montserrat800;
    font-style: normal;
    font-weight: 800;
    font-size: 46px;
    line-height: 50px;
    text-transform: uppercase;
    margin: 0 auto 84px;

    color: #000000;
  }
}

.wrapper-doc {
  display: flex;
  justify-content: space-between;
}

.documents {
  display: flex;

  &_img {
    width: 100%;
    height: 113.38px;
  }

  &_description_wrapper {
    margin: 0 0 0 48px;
  }

  &_title {
    width: 424px;
    height: 75px;
    font-family: Montserrat700;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    margin: 0 0 16px;
    color: #000000;
    display: -webkit-box;
    max-width: 424px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &_sub-title {
    font-family: Montserrat400;
    font-size: 23px;
    line-height: 28px;
    margin: 0;
  }

  &_pdf {
    padding: 40px;
  }
}

.link {
  color: #000000;
  text-decoration: none;
  display: inline-flex;
}

// .documents_wrapper{
//     width: 100%;
//     height: auto;
// }
