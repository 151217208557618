.b_g_c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  border-radius: 31.971px;
  margin: 0 auto;
  padding: 1% 1%;
  max-width: 1105px;
  box-sizing: border-box;
  box-shadow: 0 0 24.9774px rgba(0, 0, 0, 0.07);
}

.slide {
  width: 80%;
}

.carusel-cast-style {
  display: flex;
  justify-content: center;
  height: 445px;

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 380px;
    padding: 1rem;

    p {
      margin-bottom: 0;
    }
  }

  &_img {
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    margin: 0;
    border-radius: 30px;
  }
}

.carousel-control-prev {
  left: -30px !important;
}

.carousel-control-next {
  right: -30px !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: auto !important;
  opacity: 1 !important;
}

@media (max-width: 1080px) {
  .carousel {
    height: auto;
  }
  .carusel-cast-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 800px;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 380px;
    }

    &_img {
      overflow: hidden;
    }
  }

  .h2-castem {
    text-align: center;
  }
}

@media (max-width: 720px) {
  .carusel-cast-style {
    height: 620px;
  }
}

@media (max-width: 600px) {
  .carusel-cast-style {
    height: auto;
  }
}
